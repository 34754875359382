<template>
  <div style="overflow-y: auto; scrollbar-gutter: stable; max-height: 600px;">
    <div v-if="!sensors?.length" class="step-input">
      <p>No sensors found. Please add a sensor.</p>
    </div>
    <div v-for="(sensor, index) in sensors" :key="index">
      <div v-if="!sensor.deleted" class="sensor-section"
           :class="{'validation-failure': failedValidationSensors[index]}">
        <div class="sensor-header d-flex justify-content-between align-items-center">
          <h5>{{ sensorName(sensor) }}</h5>

          <div class="button-group d-flex">
            <button class="btn btn-sm btn-outline-secondary" @click="toggleSensor(index)">
              {{ sensor.collapsed ? 'Show' : 'Hide' }} Details
            </button>
            <button class="btn btn-sm ms-2" id="deleteSensorBtn" title="Delete Sensor" @click="removeSensor(index)">
              <i class="bi bi-trash" style="font-size:1.1em"></i>
            </button>
          </div>
        </div>

        <!-- Collapsible Sensor Attribute List -->
        <div v-show="!sensor.collapsed">
          <div class="row attr-row">
            <div class="col-md-4">
              <label> {{ getAttribute(index, 9151)?.attribute_pretty_name }} </label>
            </div>
            <div class="col-md-8">
              <select
                  @input="updateSensorType(index, $event.target.value)"
                  :value="getAttribute(index, 9151).attribute_value"
                  class="form-control-sm attr-input text-center">
                <option v-for="option in sensorModels" :value="option.sensor_model_id" :key="option">
                  {{ option.sensor_name }} - {{ option.sensor_type_name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row attr-row">
            <div class="col-md-4">
              <label> {{ getAttribute(index, 9150).attribute_pretty_name }} </label>
            </div>
            <div class="col-md-8">
              <input class="form-control-sm attr-input"
                     type="text"
                     v-model="getAttribute(index, 9150).attribute_value"
              />
            </div>
          </div>
          <div v-if="isPowerLine(index)">
            <div class="row attr-row">
              <div class="col-md-4">
                <label> {{ getAttribute(index, 9153).attribute_pretty_name }} </label>
              </div>
                <div class="col-md-8" v-if="is4to20">
                  <input class="form-control-sm attr-input"
                         type="number"
                         :min="1"
                         :max="4"
                         v-model="sensor.modbus_id"
                  />
                </div>
                <div class="col-md-8" v-else>
                  <input class="form-control-sm attr-input"
                         type="number"
                         :min="getAttribute(index, 9153).min"
                         :max="getAttribute(index, 9153).max"
                         v-model="getAttribute(index, 9153).attribute_value"
                  />
              </div>
            </div>
          </div>
          <div v-if="isModbus(index)">
            <div class="row attr-row">
              <div class="col-md-4">
                <label> Modbus ID </label>
              </div>
              <div class="col-md-8">
                <input class="form-control-sm attr-input"
                       type="number"
                       :min="1"
                       :max="250"
                       v-model="sensor.modbus_id"
                />
              </div>
            </div>
            <div class="row attr-row" v-if="!isOther(index)">
              <div class="col-md-4">
                <label> Reading Registers </label>
              </div>
              <div class="col-md-8">
                <Multiselect
                  mode="multiple"
                  @change="(_, instance) => updateSensorRegisters(index, instance)"
                  v-model="sensor.registers"
                  :options="readingRegisters(sensor.attributes.find(a => a.attribute_info_id === 9151)?.attribute_value)"
                  label="address"
                  track-by="address"
                  value-prop="address"
                  :multiple="true"
                  :can-clear="false"
                  :close-on-select="false"
                  :close-on-deselect="false"
                  :clear-on-select="false"
                  :clear-on-deselect="false"
                  :searchable="false"
                  :show-labels="false"
                  :hide-selected="false"
                  :object="true"
                  placeholder="true"
                >
                  <!-- Slot for the collapsed selection view -->
                  <template #placeholder>
                    <span style="cursor: pointer; text-align:center; flex-grow: 1">
                      0 addresses selected
                    </span>
                  </template>
                  <template #multiplelabel="{ values }">
                    <span style="cursor: pointer; text-align:center; flex-grow: 1">
                      {{ values && values.length > 0 ? values.length : '0' }} addresses selected
                    </span>
                  </template>
                  <template #option="{ option, isSelected }">
                    <div class="d-flex align-items-center option-item">
                      <i v-if="isSelected(option)" class="bi bi-check custom-checkmark"/>
                      {{ option?.address }}
                    </div>
                  </template>
                </Multiselect>
              </div>
            </div>
            <div v-else>
              <div class="row attr-row">
                <div class="col-md-4">
                  <label> Reading Registers </label>
                </div>
                <div class="col-md-8">
                  <OtherModbusList :sensorId="sensor.sensor_id"/>
                </div>
              </div>
            </div>
            <div class="row attr-row">
              <div class="col-md-4">
                <label> {{ getAttribute(index, 9146)?.attribute_pretty_name }} </label>
              </div>
              <div class="col-md-8">
                <select
                    v-model="getAttribute(index, 9146).attribute_value"
                    class="form-control-sm text-center attr-input">
                  <option v-if="selectedRegisters(index)?.filter(r => r.address != null)?.length === 0" disabled value="">
                    Please select registers to read
                  </option>
                  <option v-else v-for="option in selectedRegisters(index)" :value="option.address" :key="option.address">
                    {{ option.address }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Multiselect from "@vueform/multiselect";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import OtherModbusList from "@/components/shared/lists/other-modbus-list.vue";

const store = useStore();
const failedValidationSensors = ref([]);


const validate = () => {
  if (sensors.value.length <= 0) {
    return {valid: false, msg: 'No sensors found. Please add a sensor.'};
  }
  for (let i = 0; i < sensors.value.length; i++) {
    if (store.getters['ConfigWizard/isDeleted'](i)) {
      continue;
    }
    // modbus sensors must have a default address
    if (isModbus.value(i) && (getAttribute(i, 9146).attribute_value == null || getAttribute(i, 9146).attribute_value === '')) {
      failedValidationSensors.value[i] = true;
      return {valid: false, msg: 'Please select a default address for each Modbus sensor.'};
    }
    // power line sensors must have a power line value
    if (isPowerLine(i)) {
      if (is4to20(i)) {
        if (sensors.value[i]?.modbus_id == null || sensors.value[i]?.modbus_id === '') {
          failedValidationSensors.value[i] = true;
          return {valid: false, msg: 'Please select a power line for 4-20mA sensors.'};
        }
      } else {
        if (getAttribute(i, 9153).attribute_value == null || getAttribute(i, 9153).attribute_value === '') {
          failedValidationSensors.value[i] = true;
          return {valid: false, msg: 'Please select a power line for Nomad sensors.'};
        }
      }
    }
    // all modbus registers must have all fields filled out
    if (isModbus.value(i)) {
      for (let reg of selectedRegisters(i)) {
        if (reg.address == null || reg.quantity == null || reg.application_sensor_type_id == null) {
          failedValidationSensors.value[i] = true;
          return {valid: false, msg: 'Please fill out all fields for each register.'};
        }
      }
    }
    // we're good if we made it here
    failedValidationSensors.value[i] = false;
  }
  // save all the sensors
  store.dispatch('ConfigWizard/saveSensors');
  // After saving, we need to reload the gateway to get the new sensors
  let node = store.getters['MyView/getGateway'](store.state.gateway.id);
  store.dispatch('MyView/clickGateway', {node});
  // return success
  return {valid: true, msg: ''};
}

// eslint-disable-next-line no-undef
defineExpose({validate});

const sensors = computed(() => store.state.ConfigWizard.sensors);
const sensorModels = computed(() => store.state.ConfigWizard.sensorModels);
const getAttribute = (sensorIdx, attr_info_id) => store.getters['ConfigWizard/getAttribute'](sensorIdx, attr_info_id);
const toggleSensor = (index) => store.commit('ConfigWizard/toggleCollapse', index);
const removeSensor = (index) => store.commit('ConfigWizard/deleteSensor', index);
const readingRegisters = (sensorModel) => store.getters['ConfigWizard/registersForModel'](sensorModel);
const updateSensorRegisters = (sensorIdx, msInstance) => {
  let addresses = msInstance.internalValue.map(r => r.address);
  let registers = msInstance.options.filter(r => addresses.includes(r.address));
  if (registers?.length === 0) {  // deselection
    getAttribute(sensorIdx, 9146).attribute_value = null;
  }
  store.commit('ConfigWizard/updateSensorRegisters', { sensorIdx, registers });
}

const selectedRegisters = (sensorIdx) => store.getters['ConfigWizard/selectedRegisters'](sensorIdx);
const isOther = computed(() => sensorIdx => store.getters['ConfigWizard/isOther'](sensorIdx));
const updateSensorType = (sensorIdx, type_id) => store.commit('ConfigWizard/updateSensorType', { sensorIdx, type_id });
const isModbus = computed(() => sensorIdx => store.getters['ConfigWizard/isModbus'](sensorIdx));
const isPowerLine = (sensorIdx) => store.getters['ConfigWizard/isPowerLine'](sensorIdx);
const is4to20 = (sensorIdx) => store.getters['ConfigWizard/sensorProtocolTypeId'](sensorIdx) === 3;
const sensorName = (sensor) => {
  let name = sensor.attributes.find(a => a.attribute_info_id === 9150)?.attribute_value;
  return name || `${sensor.sensor_type_name} ${sensor.modbus_id}` || 'Unnamed Sensor';
}
</script>

<style scoped>
.sensor-section {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  background-color: var(--color-bg);
}

.sensor-header h5 {
  margin: 0;
}

#deleteSensorBtn:hover {
  background-color: red!important;
  border-color: red!important;
}

.sensor-header {
  position: relative;
  margin-bottom: 10px;
}

.attr-row {
  white-space: nowrap;
  background-color: var(--color-bg);
}

.attr-input {
  text-align: center;
  width: 100%;
  background-color: var(--color-bg-sec);
}

select.attr-input {
  cursor: pointer;
}

.validation-failure {
  border: 2px solid red;
}
</style>