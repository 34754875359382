<template>
  <div ref="mapContainer" class="map-container"></div>
</template>

<script setup>
import {ref, onMounted, computed, watch} from 'vue';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useStore } from 'vuex';

// Set up the access token and initial map coordinates
const accessToken = 'pk.eyJ1IjoiYmVub2l0ZHVyZXQiLCJhIjoiY2w2OXhjeHMyMTZ0ajNpbzVhM2MwaHlteiJ9.4R_hyzrZFN7bZrix5X_Nnw';
const coordinates = [-96.22526430339026, 38.80948251839888];

// References for the map container and markers
const mapContainer = ref(null);
const map = ref(null);
const markers = ref([]);

// Access the Vuex store to retrieve the gateway list
const store = useStore();
const gatewayList = computed(() => store.state.apgList.List['gateway_me']);

onMounted(() => {
  mapboxgl.accessToken = accessToken;

  // Initialize the Mapbox map
  map.value = new mapboxgl.Map({
    container: mapContainer.value,
    style: 'mapbox://styles/mapbox/navigation-day-v1',
    center: coordinates,
    zoom: 3,
  });

  // Add markers once the map has loaded
  map.value.on('load', () => {
    addMarkers();
    map.value.resize(); // Resize the map to fix any layout issues
  });
});

// Watch `gatewayList` to add markers when the data updates
watch(gatewayList, () => {
  addMarkers();
});

// Function to add markers to the map based on `gatewayList`
const addMarkers = () => {
  // Ensure the map is defined
  if (!map.value) {
    console.error("Map instance is not initialized.");
    return;
  }

  // Clear existing markers
  markers.value.forEach((marker) => marker.remove());
  markers.value = [];

  // Loop through each gateway and create a marker
  gatewayList.value.forEach((gateway) => {
    const { longitude, latitude } = gateway;

    // Add the marker to the map
    const marker = new mapboxgl.Marker()
      .setLngLat([longitude, latitude])
        .setPopup(new mapboxgl.Popup().setText(gateway.label))
        .addTo(map.value);

    markers.value.push(marker);
  });
};
</script>
