<template>
  <div style="display: flex; flex-direction: column;height:55vh;">
    <div class="address-inputs" v-if="latitude == null || longitude == null">
      <div class="address-field">
        <small class="d-block text-muted">Address</small>
        <input v-model="address" class="form-control text-center" />
      </div>
      <div class="address-field">
        <small class="d-block text-muted">City</small>
        <input v-model="city" class="form-control text-center" />
      </div>
      <div class="address-field">
        <small class="d-block text-muted">State</small>
        <input v-model="state" class="form-control text-center" />
      </div>
      <div class="address-field">
        <small class="d-block text-muted">ZIP Code</small>
        <input v-model="zip" class="form-control text-center" />
      </div>
    </div>
    <div class="button-group sticky-top">
      <button @click="findAddress" class="btn-address" :disabled="latitude != null && longitude != null">
        Find Address
      </button>
      <button @click="reset" class="btn-address">
        <i class="bi bi-arrow-counterclockwise"></i> Reset
      </button>
    </div>
    <div ref="mapContainer" class="map-container"></div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useStore } from 'vuex';

const store = useStore();

const accessToken = 'pk.eyJ1IjoiYmVub2l0ZHVyZXQiLCJhIjoiY2w2OXhjeHMyMTZ0ajNpbzVhM2MwaHlteiJ9.4R_hyzrZFN7bZrix5X_Nnw';
const map = ref(null);
const mapContainer = ref(null);
const address = ref('');
const city = ref('');
const state = ref('');
const zip = ref('');
const marker = ref(null);
let latitude = ref(null);
let longitude = ref(null);

mapboxgl.accessToken = accessToken;

onMounted(() => {
  // Initialize the Mapbox map
  map.value = new mapboxgl.Map({
    container: mapContainer.value,
    style: 'mapbox://styles/mapbox/navigation-day-v1',
    center: [-98.5795, 39.8283],  // Default to center of US
    zoom: 3,
    scrollZoom: false
  });

  map.value.on('load', () => {
    loadExisting();
    map.value.resize();
  });
});

const loadExisting = () => {
  const lon = store.state.apgList.List.gateway_attribute?.find((attr) => attr.attribute_info_id === 9136)?.attribute_value;
  const lat = store.state.apgList.List.gateway_attribute?.find((attr) => attr.attribute_info_id === 9137)?.attribute_value;
  if (lon && lat) {
    markMap(lat, lon);
  }
}

const reset = async () => {
  latitude.value = null;
  longitude.value = null;
  address.value = '';
  city.value = '';
  state.value = '';
  zip.value = '';
  if (marker.value) {
    marker.value.remove();
    marker.value = null;
  }
  map.value.flyTo({
    center: [-98.5795, 39.8283],  // Default to center of US
    zoom: 3,
    speed: 1.5,
    essential: true
  });
};

// Method to resize the map, exposed to parent
const resizeMap = () => {
  if (map.value) {
    map.value.resize();
  }
};

const saveCoordinates = async () => {
  if (latitude.value == null || longitude.value == null) {
    return;
  }
  await store.dispatch('user/upsertAttribute', {
    attribute_value: longitude.value,
    attribute_info_id: 9136,
    owner_id: store.state.gateway.id
  });
  await store.dispatch('user/upsertAttribute', {
    attribute_value: latitude.value,
    attribute_info_id: 9137,
    owner_id: store.state.gateway.id
  });
}

// eslint-disable-next-line no-undef
defineExpose({ resizeMap, saveCoordinates });

async function getCoordinates(address) {
  const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${accessToken}`
  );
  const data = await response.json();
  if (data.features && data.features.length > 0) {
    let [lg, lt] = data.features[0].center;
    return { lt, lg };
  } else {
    throw new Error('Location not found');
  }
}

const findAddress = async () => {
 let coords = await getCoordinates(`${address.value} ${city.value} ${state.value} ${zip.value}`);
 await markMap(coords.lt, coords.lg);
};

const markMap = async (lat, lon) => {
  latitude.value = lat;
  longitude.value = lon;

  // Use flyTo to pan and zoom to new coordinates
  map.value.flyTo({
    center: [longitude.value, latitude.value],
    zoom: 14,
    speed: 1.5,
    essential: true
  });

  // Update or add marker at new location
  if (marker.value) {
    marker.value.setLngLat([longitude.value, latitude.value]);
  } else {
    marker.value = new mapboxgl.Marker()
        .setLngLat([longitude.value, latitude.value])
        .addTo(map.value);
  }
};
</script>

<style scoped>
.address-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Reduced gap between fields */
  margin-bottom: 15px; /* Slightly less margin at the bottom */
}

.address-field {
  display: flex;
  flex-direction: column;
  flex: 1 1 40%; /* Slightly smaller flex basis for tighter layout */
  min-width: 130px; /* Reduced minimum width for smaller screens */
  background-color: var(--color-bg-sec);
}

.address-field small {
  margin-bottom: 3px; /* Reduced space under label */
  font-size: 0.7em; /* Smaller font for labels */
  color: var(--color-txt) !important;
}

.text-muted {
  color: var(--color-txt)!important;
}

.address-field input {
  background-color: var(--color-bg);
  border: 1px solid var(--color-txt);
  border-radius: 4px;
  color: var(--color-txt);
  padding: 4px 8px; /* Reduced padding for compact size */
  font-size: 0.85em; /* Slightly smaller font size */
  text-align: center;
  transition: background 0.3s ease, border-color 0.3s ease;
}

.address-field input:focus {
  outline: none;
  border-color: var(--color-txt);
}

.btn-address {
  background-color: var(--color-sec);
  border: none;
  color: var(--color-bg-sec);
  padding: 8px 16px; /* Smaller padding for a more compact button */
  font-size: 0.85em; /* Reduced font size */
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.btn-address:disabled {
  background-color: var(--color-border);
  cursor: not-allowed;
}

.btn-address:hover {
  background-color: var(--color-sec);
}

.button-group {
  display: flex;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.map-container {
  flex-grow: 1 !important;
  width: 100% !important;
  min-height: 300px !important;
  min-width: 800px !important;
}
</style>
