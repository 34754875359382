<template>
  <div v-if="chartLoaded" class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          <font-awesome-icon icon="cog" class="me-2"/>
          Chart Options
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse table-vertical-scroll" aria-labelledby="headingOne" data-bs-parent="#accordionExample" >
        <div class="accordion-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card">
                  <div class="card-body">
                    <div class="card-header">Time Range</div>
                    <div class="d-flex flex-wrap mb-3" role="group">
                      <button
                          v-for="range in dateRanges"
                          :key="range.value"
                          type="button"
                          class="btn btn-secondary btn-sm"
                          :class="{ 'custom-active': selectedRange === range.value }"
                          @click="selectRange(range)"
                      >
                        {{ range.label }}
                      </button>
                    </div>
                    <div class="row">
                      <div class="col-6" style="padding-right: 5px; padding-left: 5px;">
                        <label for="fromDate">From</label>
                        <input type="datetime-local" id="fromDate" class="form-control" v-model="fromDate" @change="setFromDate" />
                      </div>
                      <div class="col-6" style="padding-right: 5px; padding-left: 5px;">
                        <label for="toDate">To</label>
                        <input type="datetime-local" id="toDate" class="form-control" v-model="toDate" @change="setToDate" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card">
                  <div class="card-body">
                    <div class="card-header">AI</div>
                    <div class="form-check form-switch mb-2">
                      <input class="form-check-input" type="checkbox" role="switch"
                             id="aiSwitch"
                             @change="aiToggle"
                             :disabled="!aiEnabled"
                             :checked="aiEnabledVal"
                      />
                      <label class="form-check-label" for="aiSwitch">Predictions</label>
                    </div>
                    <div
                        class="text-primary"
                        :class="{ 'text-decoration-none': !aiEnabled, 'text-decoration-underline': aiEnabled }"
                        :style="{ cursor: aiEnabled ? 'pointer' : 'default', opacity: aiEnabled ? 1 : 0.5 }"
                        @click="aiEnabled && removeOutliers()"
                    >
                      Remove Outliers
                    </div>
                    <div class="form-check form-switch mb-2">
                      <input class="form-check-input" type="checkbox" role="switch" id="imputationSwitch" :disabled="true"/>
                      <label class="form-check-label" for="imputationSwitch">Data Imputation</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card">
                  <div class="card-body">
                    <div class="card-header">Timeout and Errors</div>
                    <div class="form-check form-switch mb-2">
                      <input class="form-check-input" type="checkbox" role="switch"
                             id="timeoutSwitch"
                             @change="timeoutToggle"
                             :disabled="!timeouts"
                             :checked="timeoutVal && timeouts"
                      />
                      <label class="form-check-label" for="timeoutSwitch">Show Timeouts</label>
                    </div>
                    <div class="form-check form-switch mb-2">
                      <input class="form-check-input" type="checkbox" role="switch" id="errorSwitch" :disabled="true"/>
                      <label class="form-check-label" for="timeoutSwitch">Show Errors</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card">
                  <div class="card-body">
                    <div class="card-header">Alarms</div>
                    <div class="form-check form-switch mb-2">
                      <input class="form-check-input" type="checkbox" role="switch"
                             id="alarmSwitch"
                             @change="alarmToggle"
                             :disabled="alarms?.length === 0"
                             :checked="alarmVal && alarms?.length > 0"
                      />
                      <label class="form-check-label" for="alarmSwitch">Show Set Alarms</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import { useStore } from "vuex"
	import {computed, onMounted} from 'vue'
  import moment from 'moment'
		
	export default {
    data() {
      return {
        dateRanges: [
          { value: '30r', label: 'Last 30 readings', diff: null },
          { value: '1h', label: 'Last hour', diff: 60 * 60 * 1000 },
          { value: '12h', label: 'Last 12 hours', diff: 12 * 60 * 60 * 1000 },
          { value: '24h', label: 'Last 24 hours', diff: 24 * 60 * 60 * 1000 },
          { value: '7d', label: 'Last 7 days', diff: 7 * 24 * 60 * 60 * 1000 },
          { value: '30d', label: 'Last 30 days', diff: 30 * 24 * 60 * 60 * 1000 },
        ],
      };
    },
    methods: {
      selectRange(range) {
        this.updateDateRange(range);
      },
    },
		props: {
      listType: String,
    },
		setup(props) {
			const store = useStore();
      let fromDate = computed(() => moment(store.state.chart.filters.dateRange.fromDate[props.listType]).local().format('YYYY-MM-DDTHH:mm'));
      let toDate = computed(() => moment(store.state.chart.filters.dateRange.toDate[props.listType]).local().format('YYYY-MM-DDTHH:mm'));

			function updateDateRange(range, update = true) {
				console.log('value dateRange', range.value);
				store.commit('chart/setDateRangeType', {listType: props.listType, dateRangeType: range.value});
        store.commit('chart/setDate',{listType: props.listType, date: new Date().toISOString(), which: 'toDate'});
        store.commit('chart/setDate',{listType: props.listType, date: new Date(Date.now() - range.diff).toISOString(), which: 'fromDate'});
        if (update)
          store.dispatch('register/loadRegister');
			}

			const setFromDate = (event) => {
        setDate(event, 'fromDate');
			}

			function setToDate(event) {
        setDate(event, 'toDate');
			}

      function setDate(event, which) {
        store.commit('chart/setDate',{listType: props.listType, date: moment(event.target.value).toISOString(), which: which});
        store.commit('chart/setDateRangeType', {listType: props.listType, dateRangeType: 'custom'});
        if (fromDate.value.length > 0 && toDate.value.length > 0) {
          store.dispatch('register/loadRegister');
          document.querySelector('.custom-active')?.classList.remove('custom-active');
        }
      }

			onMounted(() => {
        updateDateRange({ value: '30r', label: 'Last 30 readings', diff: null }, false);
      });

      const aiToggle = (e) => {
        store.commit("chart/setShowingPredictions", e.target.checked, { root: true });
      };

      const timeoutToggle = (e) => {
        store.commit("chart/setShowingTimeouts", e.target.checked, { root: true });
      };

      const alarmToggle = (e) => {
        store.commit("chart/setShowingAlarms", e.target.checked, { root: true });
      };

      const aiEnabled = computed(() => {
        let regAttrs = store.state.apgList.List.register_attribute;
        if (!regAttrs) return false;
        return regAttrs.find((item) => item?.attribute_name === 'predictions_enabled')?.attribute_value === 'true';
      });

      const removeOutliers = () => {
        store.dispatch("chart/removeOutliers", {
          listType: props.listType,
          id: store.state.sensor.id,
          address: store.state.apgList.List.sensor_overview?.find((item) => item.attribute_name === 'addressToRead')?.attribute_value,
        }, { root: true });
      };

			return {
				updateDateRange,
				setFromDate,
				setToDate,
				daterange: computed(() => store.state.chart.filters.dateRangeType[props.listType]),
        chartLoaded: computed(() => !store.state.settings.is_loading),
        selectedRange: computed(() => store.state.chart.filters.dateRangeType[props.listType]),
				fromDate,
				toDate,
        aiToggle,
        aiEnabled,
        aiEnabledVal: computed(() => store.state.chart.showing_predictions),
        removeOutliers,
        timeouts: computed(() => store.state.chart.unresponsive_data.group_gateway_sensor?.length > 0),
        timeoutToggle,
        timeoutVal: computed(() => store.state.chart.filters.showingTimeouts),
        alarms: computed(() => store.state.alarm.alarm_set),
        alarmToggle,
        alarmVal: computed(() => store.state.chart.filters.showingAlarms),
			}
		}
	}
</script>

<style scoped>
.accordion {
  --bs-accordion-color: var(--color-txt);
}

input[type='date'] {
  pointer-events: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  pointer-events: auto;
  color-scheme: var(--scheme);
}

.card-header {
  margin-bottom: 15px;
}

.custom-active {
  color: var(--color-txt) !important;
  background-color: var(--color-sec) !important;
  border-color: var(--color-sec) !important;
}
</style>
